import whitePepper from "../../img/products/lada-putih.png";
import blackPepper from "../../img/products/lada-hitam.png";
import kelapaParut from "../../img/products/kelapa-parut-new-size.jpg";
import ndcEmber from "../../img/products/nata-de-coco.png";
import ndc1000 from "../../img/products/ndc-1000.png";
import ndcSlice from "../../img/products/slice-1000.png";
import santan200 from "../../img/products/santan-200.png";
import santan1000 from "../../img/products/santan-1000.png";
import cookingOil from "../../img/products/coconut-cooking-oil.jpg";
import rbdCoconutOil from "../../img/products/rbd-coconut-oil.jpg";
import virginCoconutOil from "../../img/products/virgin-coconut-oil.jpg";
import asepticCoconutWater from "../../img/products/Aseptic-Single-Strength-Coconut-Water.jpg";
import crudeCoconutOil from "../../img/products/CNO.jpg";
import coconutFattyAcid from "../../img/products/CFAD.jpg";
import pineappleJuiceConcentrate from "../../img/products/Jus-Nanas-Konsentrat.jpg";
import cannedPineapple from "../../img/products/canned-pineapple.jpg";
import coconutPellet from "../../img/products/Coconut-Expeller-Pellet-new.png";

const products = [
  {
    category: "Indonesian Spices",
    productList: [
      {
        name: "White Pepper",
        image: whitePepper,
        desc: "White pepper, ripe and characterized by reddish or yellowish color changes, undergoes peeling, soaking, and drying to preserve its distinctive taste and aroma. The International Pepper Community tests quality based on shape, color, and sensory characteristics. Indonesia's white pepper production is primarily concentrated in Bangka Belitung, Sulawesi, and Kalimantan Provinces.",
        spec: [
          {
            type: "*report analysis by request",
            list: "-",
          },
        ],
        size: ["-"],
      },
      {
        name: "Black Pepper",
        image: blackPepper,
        desc: "Black pepper, a versatile ingredient with a strong spicy taste and long shelf life, is dried and standardized by the International Pepper Community (IPC) for quality parameters such as shape, color, and sensory characteristics.",
        spec: [
          {
            type: "*report analysis by request",
            list: "-",
          },
        ],
        size: ["-"],
      },
    ],
  },
  {
    category: "Coconut Products",
    productList: [
      {
        name: "Dessicated Coconut 25 kg",
        image: kelapaParut,
        desc: "Grated coconut is fresh and ripe coconut flesh that has been peeled, grated, ground, dried, sifted, and processed hygienically. Raw materials that are rich in dietary fiber, healthy, and good for making biscuits and cakes with a unique texture.",
        spec: [
          {
            type: "LFDC (Fine and Medium)",
            list: "-",
          },
          {
            type: "HFDC",
            list: "-",
          },
        ],
        size: ["Packaging 25 kg"],
      },
      {
        name: "Coconut Expeller Pellet",
        image: coconutPellet,
        desc: "Coconut Expeller Pellet is made from grated coconut cake whose water content has been removed through a heating process. Coconut Expeller Pellet is one of the ingredients used to make animal feed.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["50 kg sack packaging", "Bulk ship"],
      },
      {
        name: "Kara Nata De Coco 1kg Bucket",
        image: ndcEmber,
        desc: "KARA Nata de coco is a chewy textured food made from fermented coconut milk. The high and natural fiber contained in nata de coco can help prevent constipation and maintain the digestive system. Therefore, KARA Nata de coco can function as an alternative food to maintain normal body weight. KARA Nata de coco is suitable for consumption with other snacks, such as drinks, ice cream, pudding, and fruit cocktails.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["Packaging 1kg"],
      },
      {
        name: "Kara Nata De Coco 1000 gr",
        image: ndc1000,
        desc: "KARA Nata de coco is a chewy textured food made from fermented coconut milk. The high and natural fiber contained in nata de coco can help prevent constipation and maintain the digestive system. Therefore, KARA Nata de coco can function as an alternative food to maintain normal body weight. KARA Nata de coco is suitable for consumption with other snacks, such as drinks, ice cream, pudding, and fruit cocktails.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["-"],
      },
      {
        name: "Kara Nata De Coco Slice 1kg",
        image: ndcSlice,
        desc: "KARA Nata de coco in the form of Slice is a chewy textured food made from fermented coconut milk. The high and natural fiber contained in nata de coco can help prevent constipation and maintain the digestive system. Therefore, KARA Nata de coco can function as an alternative food to maintain normal body weight. KARA Nata de coco is suitable for consumption with other snacks, such as drinks, ice cream, pudding, and fruit cocktails.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["-"],
      },
      {
        name: "Kara Ready-to-Use Coconut Milk 200 ml",
        image: santan200,
        desc: "With the latest technology, KARA presents coconut milk that is processed using UHT  (Ultra-High Temperature) and aseptically packaged in a practical packaging. This coconut milk product meets various food quality standards while maintaining the freshness and natural taste of coconut.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["-"],
      },
      {
        name: "Kara Ready-to-Use Coconut Milk 1000 ml",
        image: santan1000,
        desc: "With the latest technology, KARA presents coconut milk that is processed using UHT (Ultra-High Temperature) and aseptically packaged in a practical packaging. This coconut milk product meets various food quality standards while maintaining the freshness and natural taste of coconut.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["-"],
      },
      {
        name: "Kara Coconut Cooking Oil",
        image: cookingOil,
        desc: "KARA Coconut Cooking Oil is processed in a modern and hygienic way. Clearer, without using artificial flavors and colors. KARA Coconut Cooking Oil can be used repeatedly because it is more stable at high temperatures.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["5 L jerry can packaging", "18 L jerry can packaging"],
      },
      {
        name: "RBD (Refined, Bleached, Deodorized) Coconut Oil",
        image: rbdCoconutOil,
        desc: "RBD coconut oil is coconut oil that has been refined, bleached, and processed (RBD). RBD is widely used in cosmetics, soap production, and food.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: [
          "15 kg bucket packaging",
          "180 kg drum packaging",
          "Flexitank",
          "Tanker",
        ],
      },
      {
        name: "Virgin Coconut Oil",
        image: virginCoconutOil,
        desc: "Virgin coconut oil is a clear oil extracted from fresh coconut flesh through a special mechanical process, without removing the coconut aroma. No chemicals are added during processing. In other words, virgin coconut oil is non-RBD/unrefined oil. VCO is widely used for health and beauty products.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["15 kg bucket packaging", "180 kg drum packaging"],
      },
      {
        name: "Aseptic Single Strength Coconut Water",
        image: asepticCoconutWater,
        desc: "Aseptic Single Strength Coconut Water is a clear liquid from the liquid endosperm that is naturally formed from fresh ripe coconuts, without preservatives and additives. This product is 100% sterile and can be used for commercial purposes.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: [
          "20 kg bag packaging",
          "200 kg drum packaging",
          "1,000 kg flexbox packaging",
        ],
      },
      {
        name: "CNO (Crude Coconut Oil)",
        image: crudeCoconutOil,
        desc: "CNO and CFAD are often used as raw materials for making soap, shampoo, and cosmetic products.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["1,000 kg tanker"],
      },
      {
        name: "CFAD (Coconut Fatty Acid)",
        image: coconutFattyAcid,
        desc: "CNO and CFAD are often used as raw materials for making soap, shampoo, and cosmetic products.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["Tanker"],
      },
    ],
  },
  {
    category: "Others",
    productList: [
      {
        name: "Pineapple Juice Concentrate",
        image: pineappleJuiceConcentrate,
        desc: "Pineapple Juice Concentrate is made from ripe pineapple extraction that goes through a process of concentration, evaporation, and pasteurization before being filled into the packaging. Contains no preservatives.",
        spec: [
          {
            type: "-",
            list: "-",
          },
        ],
        size: ["200 kg drum packaging"],
      },
      {
        name: "Canned Pineapple",
        image: cannedPineapple,
        desc: "Available in slices, chunks, triangles, etc. Canned pineapple is processed from the Spanish Queen pineapple variety with a sweet taste, fragrant aroma, and attractive color.",
        spec: [
          {
            type: "Cut",
            list: ["Triangular cut", "Chunk", "Slices", "Pieces"],
          },
          {
            type: "Syrup",
            list: ["Water", "Natural Juice", "Light Syrup", "Heavy Syrup"],
          },
        ],
        size: ["A10 Can Packaging"],
      },
    ],
  },
];

export default products;
