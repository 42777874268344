import starbucksLogo from "../../img/client-logo/starbucks.png";
import astonLogo from "../../img/client-logo/aston-inn.png";
import kfcLogo from "../../img/client-logo/kfc.png";
import ibisHotelLogo from "../../img/client-logo/ibis-hotels.png";
import ibisStylesLogo from "../../img/client-logo/ibis-styles.png";
import jwMarriottLogo from "../../img/client-logo/JW-Marriott.png";
import pizzaHutLogo from "../../img/client-logo/pizza-hut.png";
import ritzCarltonLogo from "../../img/client-logo/the-ritz-carlton.png";
import wingStopLogo from "../../img/client-logo/wing-stop.png";

const clientItem = [
  {
    name: "Starbucks",
    logo: starbucksLogo,
    desc: "Starbucks",
  },
  {
    name: "Aston Inn",
    logo: astonLogo,
    desc: "Aston Inn",
  },
  {
    name: "KFC",
    logo: kfcLogo,
    desc: "KFC",
  },
  {
    name: "Ibis Hotels",
    logo: ibisHotelLogo,
    desc: "Ibis Hotel",
  },
  {
    name: "Ibis Styles",
    logo: ibisStylesLogo,
    desc: "Ibis Styles",
  },
  {
    name: "JW Marriott",
    logo: jwMarriottLogo,
    desc: "JW Marriot",
  },
  {
    name: "Pizza Hut",
    logo: pizzaHutLogo,
    desc: "Pizza Hut",
  },
  {
    name: "Ritz Carlton",
    logo: ritzCarltonLogo,
    desc: "Ritz Carlton",
  },
  {
    name: "Wing Stop",
    logo: wingStopLogo,
    desc: "Wing Stop",
  },
];

const coreValues = [
  {
    title: "Authentic",
    desc: "We offer genuine products that reflect the essence of Indonesia's cultural heritage.",
  },
  {
    title: "Original",
    desc: "Our products maintain the authenticity of traditional cultivation and processing methods.",
  },
  {
    title: "Exotic",
    desc: "We bring the rich and diverse flavors of Indonesia to the global market.",
  },
];

const missions = [
  {
    desc: "To provide the finest Indonesian products, sourced from the best regions in Indonesia, utilizing our 21 years of expertise in the food supply industry.",
  },
  {
    desc: "To uphold sustainable and ethical sourcing practices that benefit local farmers and the environment.",
  },
  {
    desc: "To promote the rich cultural heritage of Indonesian products globally, educating consumers about their unique flavors, benefits, and origins.",
  },
];

export { clientItem, coreValues, missions };
