import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <div className="contact-wrapper">
        <div className="hayoke-company">
          <div className="title">HAYOKE GLOBAL TRADE</div>
          <div className="sub-title">PT. Hayoke Anugerah Sejahtera</div>
          <div className="company-info">
            <div>
              Ruko Pulo Gebang Permai Blok D5 No. 22-24, Cakung-East
              Jakarta 13950
            </div>
            <div>+62 877 8104 4200</div>
            <div>+62 813 8470 8845</div>
            <div>+31 6 45882899</div>
            <div>info@hayokeglobaltrade.com</div>
            <div className="opening-hours">
              <div>Monday-Friday 08.00-17.00</div>
              <div className="closed-days">Closed over the weekend</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
