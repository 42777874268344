import React from "react";
import { Layout, Menu } from "antd";
import CompanyFooter from "./components/footer/footer";
import menuIcon from "./img/pt-hayoke-menu-icon.png";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/home/home";
import "./App.css";
import Contact from "./pages/contact/contact";
import AboutUs from "./pages/aboutUs/aboutUs";
import Products from "./pages/products/products";

const { Header, Content, Footer } = Layout;

const items = [
  {
    key: "/",
    label: "Home",
  },
  {
    key: "/about-us",
    label: "About Us",
  },
  {
    key: "/products",
    label: "Products",
  },
  {
    key: "/contact",
    label: "Contact",
  },
];
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Layout className="layout">
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="demo-logo"
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img
            src={menuIcon}
            alt="menu"
            style={{
              width: "45px",
              height: "45px",
            }}
          />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[location.pathname]}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
          }}
          onClick={({ key }) => {
            navigate(key);
          }}
        />
      </Header>
      <Content
        className="content-background"
      >
        <Contents />
      </Content>
      <Footer
        style={{
          backgroundColor: "#f2f2f2",
          padding: "12px 12px 12px 12px",
        }}
      >
        <CompanyFooter />
      </Footer>
    </Layout>
  );
};

function Contents() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}
export default App;
