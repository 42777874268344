import React from "react";
import "./footer.css";
import companyLogo from "../../img/hayoke-pt-footer.png";
import instagramLogo from "../../img/instagram.png";

const CompanyFooter = () => {
  return (
    <>
      <div className="footer-wrapper">
        <img
          src={companyLogo}
          alt="logo-company"
          style={{
            width: "250px",
            height: "250px",
          }}
        />
        <div className="footer-information">
          <div className="email-wrapper">
            <div>Email</div>
            <div className="email">info@hayokeglobaltrade.com</div>
          </div>
          <div className="telephone-wrapper">
            <div>Telephone</div>
            <div className="telephone">+62 877 8104 4200</div>
            <div className="telephone">+62 813 8470 8845</div>
            <div className="telephone">+31 6 45882899</div>
          </div>
          <div className="address-wrapper">
            <div>Address</div>
            <div className="address">
              Ruko Pulo Gebang Permai Blok D5 No. 22-24, Cakung-East
              Jakarta 13950
            </div>
          </div>
          <div className="socmed-wrapper">
            <div>Social Media</div>
            <a
              className="instagram-wrapper"
              href="https://www.instagram.com/hayokeglobaltrade.id"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagramLogo}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <div className="instagram">@hayokeglobaltrade.id</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyFooter;
