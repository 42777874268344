import React from "react";
import "./aboutUs.css";
import { clientItem, coreValues, missions } from "./item";

const AboutUs = () => {
  return (
    <>
      <div className="about-us-wrapper">
        <div className="image-background">
          <div className="text-container">
            <div className="title">
              Unveiling <span style={{ color: "#c1914a" }}>Indonesia's</span>{" "}
              Essence to the World
            </div>
            <div className="company-description">
              Hayoke Global Trade Company is a global trading enterprise in
              Indonesia under the auspices of PT. Hayoke Anugerah Sejahtera,
              specializing in the sale of Indonesian products such as spices and
              processed industrial goods (desiccated coconut, nata de coco,
              coconut milk powder, and more). As part of a group with PT.
              Intipangan Anugerah and CV. Indopangan Anugerah, which have been
              operating in Indonesia's food supply industry since 2003, we
              leverage over two decades of expertise to bring the finest
              Indonesian products to the global market.
            </div>
            <div className="goals">
              Our dedication to quality, sustainability, and customer
              satisfaction positions us as a trusted partner in trading
              Indonesian products on the international stage. Our commitment to
              authenticity, originality, and exotic flavors sets us apart in the
              competitive trade industry.
            </div>
          </div>
        </div>
        <div className="vision-mission">
          <div className="vision-container">
            <div className="about-us-title">VISION</div>
            <div className="vision-description">
              To bring the richness and diversity of Indonesian products to
              global markets, ensuring the highest quality in every shipment and
              showcasing the heritage of Indonesian products to the world.
            </div>
          </div>
          <div className="mission-container">
            <div className="about-us-title">MISSION</div>
            <div className="mission-description">
              {missions.map((mission, index) => (
                <div className="value-card">
                  <div className="value-description">{mission.desc}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="core-values">
          <div className="about-us-title">CORE VALUES</div>
          <div className="value-container">
            {coreValues.map((value, index) => (
              <div className="value-card-core">
                <div className="value-title">{value.title}</div>
                <div className="value-description-core">{value.desc}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="clients">
          <div className="about-us-title">OUR CLIENTS</div>
          <div className="client-container">
            {clientItem.map((client, index) => (
              <div className="client-card">
                <img
                  className="client-img"
                  src={client.logo}
                  alt={client.desc}
                />
                {/* <div>{client.name}</div> */}
              </div>
            ))}
          </div>
        </div>
        <div className="file-download">
          <div className="about-us-title">File Download</div>
          <a
            className="download-link"
            href="/files/Hayoke Global Trade CP Indonesia.pdf"
            download
          >
            Download Company Profile - Indonesia
          </a>
          <a
            className="download-link"
            href="/files/Hayoke Global Trade CP English.pdf"
            download
          >
            Download Company Profile - English
          </a>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
