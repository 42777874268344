import React from "react";
import { useNavigate } from "react-router-dom";
import "./home.css";
import hayokeHomepage from "../../img/hayoke-homepage.png";
import kelapaParut from "../../img/products/kelapa-parut-new-size.jpg";
import ladaPutih from "../../img/products/lada-putih.png";
import nataDeCoco from "../../img/products/nata-de-coco.png";

const productItem = [
  {
    title: "Dessicated Coconut",
    image: kelapaParut,
  },
  {
    title: "White Pepper",
    image: ladaPutih,
  },
  {
    title: "Nata De Coco",
    image: nataDeCoco,
  },
];

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="background-container"></div>
      <div className="about-us-container">
        <img
          className="company-logo-homepage"
          src={hayokeHomepage}
          alt="Hayoke Logo & Value"
        />
        <div className="summary-container">
          <div className="title">ABOUT US</div>
          <div className="description">
            Hayoke Global Trade Company is a global trading enterprise in
            Indonesia under the auspices of PT. Hayoke Anugerah Sejahtera,
            specializing in the sale of Indonesian products such as spices and
            processed industrial goods (desiccated coconut, nata de coco,
            coconut milk powder, and more). As part of a group with PT.
            Intipangan Anugerah and CV. Indopangan Anugerah, which have been
            operating in Indonesia's food supply industry since 2003, we
            leverage over two decades of expertise to bring the finest
            Indonesian products to the global market.
          </div>
          <div className="more-button" onClick={() => navigate("/about-us")}>
            Discover More →
          </div>
        </div>
      </div>
      <div className="products-container">
        <div className="title">OUR PRODUCTS</div>
        <div className="card-container">
          {productItem.map((product, index) => (
            <div>
              <div
                className="card"
                style={{ backgroundImage: `url(${product.image})` }}
              ></div>
              <div className="card-title">{product.title}</div>
            </div>
          ))}
        </div>
        <div className="more-button" onClick={() => navigate("/products")}>
          Discover More →
        </div>
      </div>
    </>
  );
};

export default Home;
