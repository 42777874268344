import React, { useState } from "react";
import "./products.css";
import products from "./product";
import { Modal } from "antd";

const Products = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    image: "",
    desc: "",
    spec: [],
    size: [],
  });
  const showModal = (title, image, desc, spec, size) => {
    setModalContent({ title, image, desc, spec, size });
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const isValidSpec = (spec) => {
    return spec.some((item) => item.type !== "-");
  };

  const isValidSize = (size) => {
    return size.some((item) => item !== "-");
  };

  return (
    <>
      <div className="product-wrapper">
        {products.map((categoryItem, categoryIndex) => (
          <div key={categoryIndex} className="category-container">
            <h2 className="title-category">{categoryItem.category}</h2>
            <div className="product-list">
              {categoryItem.productList.map((productItem, productIndex) => (
                <div
                  key={productIndex}
                  className="product-card"
                  onClick={() =>
                    showModal(
                      productItem.name,
                      productItem.image,
                      productItem.desc,
                      productItem.spec,
                      productItem.size
                    )
                  }
                >
                  <img
                    src={productItem.image}
                    alt={productItem.name}
                    className="product-image"
                  />
                  <div className="product-name">{productItem.name}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Modal
        title={modalContent.title}
        open={isModalOpen}
        onCancel={handleClose}
        footer={null}
        centered
      >
        <div className="image-desc-container">
          <img
            className="image-modal"
            src={modalContent.image}
            alt={modalContent.title}
          />
          <p className="desc-modal">{modalContent.desc}</p>
        </div>
        <div className="spec-size-container">
          {(isValidSpec(modalContent.spec) ||
            isValidSize(modalContent.size)) && (
            <>
              {isValidSpec(modalContent.spec) && (
                <>
                  <div className="spec-container">
                    <div className="modal-title">Specification</div>
                    <ul className="spec-list">
                      {modalContent.spec.map(
                        (specItem, specIndex) =>
                          specItem.type !== "-" && (
                            <li key={specIndex}>
                              <div>{specItem.type}</div>
                              {Array.isArray(specItem.list) &&
                                specItem.list.length > 0 && (
                                  <ul>
                                    {specItem.list.map(
                                      (listItem, listIndex) =>
                                        listItem !== "-" && (
                                          <li key={listIndex}>{listItem}</li>
                                        )
                                    )}
                                  </ul>
                                )}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </>
              )}
              {isValidSize(modalContent.size) && (
                <>
                  <div className="size-container">
                    <div className="modal-title">Size</div>
                    <ul className="size-list">
                      {modalContent.size.map((listSize, sizeIndex) => (
                        <li key={sizeIndex}>{listSize}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Products;
